import { FC, useMemo, useState } from 'react';
import { Controller, FieldErrors, UseFormReturn } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';

import { TOKEN_DECIMALS } from '@/common/constants/common.constants';
import { ELanguage } from '@/common/enums';
import Alert from '@/components/atom/Alert';
import AppFormField from '@/components/atom/AppFormField';
import AppButton from '@/components/atom/Button/Button';
import AppImageUploading from '@/components/atom/ImageUploading/ImageUploading';
import Switcher from '@/components/atom/Switcher';
import AppTextArea from '@/components/atom/TextArea/TextArea';
import TextField from '@/components/atom/TextField/TextField';
import { useProjectLanguageMode } from '@/contexts';
import { formatNumberWithCommas } from '@/lib/utils';
import { IStepThreeFormItem } from '@/module/projectsList/project.interface';

interface IFormScreenProps {
  currentReturnFormValue: UseFormReturn<IStepThreeFormItem>;
  allReturnFormValues: Record<ELanguage, IStepThreeFormItem>;
  allReturnFormErrors: Record<ELanguage, FieldErrors<IStepThreeFormItem>>;
  onSubmitForm: () => void;
  onCancelForm: () => void;
  syncReturnFormValues: () => void;
  validateAllReturnForms: () => Promise<boolean>;
}

const FormScreen: FC<IFormScreenProps> = ({
  currentReturnFormValue,
  allReturnFormErrors,
  onSubmitForm,
  onCancelForm,
  syncReturnFormValues,
  validateAllReturnForms,
}) => {
  const [thumbnailPreview, setThumbnailPreview] = useState<ImageListType[]>([]);

  const { languageMode, isRootLanguageMode } = useProjectLanguageMode();
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = currentReturnFormValue;

  const disabled = useMemo(() => {
    return getValues('isPublic') ?? false;
  }, [getValues]);

  const hasFormError = useMemo(() => {
    return Object.values(allReturnFormErrors).some((error) => Object.keys(error).length > 0);
  }, [allReturnFormErrors]);

  const onSubmit = async () => {
    syncReturnFormValues();
    const isValid = await validateAllReturnForms();
    if (!isValid) {
      return;
    }
    onSubmitForm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-14 py-16">
      <div className="flex flex-col items-start space-y-14 px-3">
        <div className="mb-2 flex w-full flex-col gap-6" key={`returnForm-${languageMode}`}>
          <AppFormField textLabel="リターン名" required={isRootLanguageMode} errorText={errors.returnName?.message}>
            <Controller
              control={control}
              name="returnName"
              render={({ field }) => (
                <TextField
                  disabled={disabled && getValues('isNft')}
                  error={errors.returnName}
                  placeholder="映画鑑賞チケット"
                  {...field}
                />
              )}
            />
          </AppFormField>

          <AppFormField
            textLabel="サムネイル画像"
            required={true}
            hint={errors.thumbnailUrl ? '' : '縦横比1:1'}
            errorText={errors.thumbnailUrl?.message as string}
          >
            <Controller
              name="thumbnailUrl"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AppImageUploading
                  onChange={(image) => {
                    onChange(image[0]?.file || '');
                    setThumbnailPreview(image as ImageListType[]);
                  }}
                  width="w-[393px]"
                  height="h-[393px]"
                  value={thumbnailPreview}
                  defaultValue={value}
                  disabled={!isRootLanguageMode || (disabled && getValues('isNft'))}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="リターンをNFTとして送付" required={true} errorText={errors.isNft?.message as string}>
            <div className="flex items-center gap-1">
              <Controller
                name="isNft"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switcher onCheckedChange={onChange} checked={value} disabled={!isRootLanguageMode || disabled} />
                )}
              />
              {(disabled || !isRootLanguageMode) && (
                <p className="text-[11px] font-medium leading-4 text-secondary-500">変更不可</p>
              )}
            </div>
          </AppFormField>

          <AppFormField
            textLabel="複数個選択可"
            required={true}
            errorText={errors.isQuantitySelectable?.message as string}
          >
            <div className="flex items-center gap-1">
              <Controller
                name="isQuantitySelectable"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switcher onCheckedChange={onChange} checked={value} disabled={!isRootLanguageMode} />
                )}
              />
              {!isRootLanguageMode && <p className="text-[11px] font-medium leading-4 text-secondary-500">変更不可</p>}
            </div>
          </AppFormField>

          <AppFormField
            textLabel="面談の有無"
            required={true}
            errorText={errors.isInterviewRequired?.message as string}
          >
            <div className="flex items-center gap-1">
              <Controller
                name="isInterviewRequired"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switcher onCheckedChange={onChange} checked={value} disabled={!isRootLanguageMode || disabled} />
                )}
              />
              {(disabled || !isRootLanguageMode) && (
                <p className="text-[11px] font-medium leading-4 text-secondary-500">変更不可</p>
              )}
            </div>
          </AppFormField>

          <AppFormField textLabel="金額AP" required={true} errorText={errors.apAmount?.message}>
            <Controller
              control={control}
              name="apAmount"
              render={({ field }) => (
                <TextField
                  type="text"
                  error={errors.apAmount}
                  placeholder="金額APを入力する"
                  {...field}
                  value={field.value ? formatNumberWithCommas(field.value) : ''}
                  disabled={!isRootLanguageMode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/,/g, '');
                    const newValue = Number(Number(value).toFixed(TOKEN_DECIMALS.AP));
                    field.onChange(newValue || '');
                  }}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="リターン上限数" required={true} errorText={errors.quantity?.message}>
            <Controller
              control={control}
              name="quantity"
              render={({ field }) => (
                <TextField
                  type="text"
                  error={errors.quantity}
                  placeholder="リターン上限数を入力する"
                  {...field}
                  value={field.value ? formatNumberWithCommas(field.value) : ''}
                  disabled={!isRootLanguageMode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/,/g, '');
                    const newValue = Number(Number(value).toFixed(TOKEN_DECIMALS.AP));
                    field.onChange(newValue || '');
                  }}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="リターン内容" required={isRootLanguageMode} errorText={errors.content?.message}>
            <Controller
              control={control}
              name="content"
              render={({ field }) => (
                <AppTextArea
                  custome="h-[125px]"
                  placeholder="リターン内容を入力する"
                  error={errors.content}
                  {...field}
                />
              )}
            />
          </AppFormField>
        </div>
      </div>

      {hasFormError && <Alert variant="error" message={'入力に不備があります。必須項目を全て入力してください'} />}

      <div className="flex gap-2">
        <AppButton variant="outline" text="キャンセル" size="lg" onClick={onCancelForm} />
        <AppButton text="保存" size="lg" type="submit" />
      </div>
    </form>
  );
};

export default FormScreen;
