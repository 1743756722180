import { format } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';

import { generateErrorLimitMsg, TOKEN_DECIMALS } from '@/common/constants/common.constants';
import { toEndOfDayUTC, toStartOfDayUTC } from '@/common/helpers';
import AppFormField from '@/components/atom/AppFormField';
import AppButton from '@/components/atom/Button/Button';
import AppDatePicker from '@/components/atom/Datepicker/Datepicker';
import AppImageUploading from '@/components/atom/ImageUploading/ImageUploading';
import AppTextArea from '@/components/atom/TextArea/TextArea';
import TextField from '@/components/atom/TextField/TextField';
import { useProjectLanguageMode } from '@/contexts';
import { formatNumberWithCommas } from '@/lib/utils';
import { EStatusProject } from '@/module/projectsList/constants';
import { IProjectForm } from '@/module/projectsList/project.interface';

interface StepOneProps {
  onNextStep: (step: number) => void;
  onSaveDraft: () => void;
  errorMsg?: string;
  register: UseFormRegister<IProjectForm>;
  errors: FieldErrors<IProjectForm>;
  watch: UseFormWatch<IProjectForm>;
  control: Control<IProjectForm>;
  status?: EStatusProject;
}

const StepOne: FC<StepOneProps> = ({ onNextStep, onSaveDraft, register, errors, watch, control, status }) => {
  const watchedValues = watch();
  const { languageMode, isRootLanguageMode } = useProjectLanguageMode();
  const [thumbnailPreview, setThumbnailPreview] = useState<ImageListType[]>([]);
  const [practitionerThumbnailPreview, setPractitionerThumbnailPreview] = useState<ImageListType[]>([]);

  const onSubmit = useCallback(() => {
    onNextStep(2);
  }, [onNextStep]);

  return (
    <div className="px-20 py-16">
      <form className="flex flex-col items-start space-y-14">
        <div className="mb-2 flex w-full flex-col gap-6">
          <div key={`title-${languageMode}`}>
            <AppFormField
              textLabel="プロジェクトタイトル"
              required={isRootLanguageMode}
              hint={errors.title ? '' : generateErrorLimitMsg(5, 50)}
              errorText={errors.title?.message}
            >
              <TextField error={errors.title} placeholder="プロジェクトタイトルを入力する" {...register('title')} />
            </AppFormField>
          </div>

          <div key={`summary-${languageMode}`}>
            <AppFormField
              textLabel="プロジェクト概要"
              required={isRootLanguageMode}
              hint={errors.summary ? '' : generateErrorLimitMsg(30, 200)}
              errorText={errors.summary?.message}
            >
              <Controller
                control={control}
                name="summary"
                render={({ field }) => (
                  <AppTextArea placeholder="プロジェクト概要を入力する" error={errors.summary} {...field} />
                )}
              />
            </AppFormField>
          </div>

          <AppFormField
            textLabel="サムネイル画像"
            required={true}
            hint={errors.thumbnailUrl ? '' : '3:2(横1200px 縦800px 程度)'}
            errorText={errors.thumbnailUrl?.message as string}
          >
            <Controller
              name="thumbnailUrl"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AppImageUploading
                  onChange={(image) => {
                    onChange(image[0]?.file || '');
                    setThumbnailPreview(image as ImageListType[]);
                  }}
                  value={thumbnailPreview}
                  defaultValue={value}
                  disabled={!isRootLanguageMode}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="目標AP" required={true} errorText={errors.targetApAmount?.message}>
            <Controller
              control={control}
              name="targetApAmount"
              render={({ field }) => (
                <TextField
                  error={errors.targetApAmount}
                  type="text"
                  placeholder="目標APを入力する"
                  {...field}
                  value={field.value ? formatNumberWithCommas(field.value) : ''}
                  disabled={!isRootLanguageMode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/,/g, '');
                    if (value === '' || /^\d+$/.test(value)) {
                      const newValue = value === '' ? '' : Number(value).toFixed(TOKEN_DECIMALS.AP);
                      field.onChange(newValue);
                    }
                  }}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="募集開始日" required={true} errorText={errors.startDate?.message}>
            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, value, ref } }) => (
                <AppDatePicker
                  value={new Date(value || '')}
                  dateFormat="yyyy/MM/dd"
                  error={errors.startDate}
                  minDate={new Date()}
                  maxDate={new Date(watchedValues.endDate) || null}
                  placeholder={format(new Date(), 'yyyy/MM/dd')}
                  disabled={!isRootLanguageMode}
                  ref={ref}
                  onChange={(date) => {
                    onChange(date ? toStartOfDayUTC(date).toISOString() : '');
                  }}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="募集終了日" required={true} errorText={errors.endDate?.message}>
            <Controller
              control={control}
              name="endDate"
              render={({ field: { onChange, value, ref } }) => (
                <AppDatePicker
                  dateFormat="yyyy/MM/dd"
                  minDate={new Date(watchedValues.startDate) || null}
                  error={errors.endDate}
                  value={new Date(value || '')}
                  placeholder={format(new Date(), 'yyyy/MM/dd')}
                  disabled={!isRootLanguageMode}
                  ref={ref}
                  onChange={(date) => {
                    onChange(date ? toEndOfDayUTC(date).toISOString() : '');
                  }}
                />
              )}
            />
          </AppFormField>

          <div key={`practitionerName-${languageMode}`}>
            <AppFormField
              textLabel="プロジェクト実行者名"
              required={isRootLanguageMode}
              hint={errors.practitionerName ? '' : generateErrorLimitMsg(3, 30)}
              errorText={errors.practitionerName?.message}
            >
              <TextField
                error={errors.practitionerName}
                placeholder="プロジェクト実行者名を入力する"
                {...register('practitionerName')}
              />
            </AppFormField>
          </div>

          <div key={`practitionerProfile-${languageMode}`}>
            <AppFormField
              textLabel="プロジェクト実行者プロフィール"
              required={isRootLanguageMode}
              hint={errors.practitionerProfile ? '' : generateErrorLimitMsg(50, 300)}
              errorText={errors.practitionerProfile?.message}
            >
              <Controller
                control={control}
                name="practitionerProfile"
                render={({ field }) => (
                  <AppTextArea
                    custome="h-[125px]"
                    placeholder="プロジェクト実行者プロフィールを入力する"
                    error={errors.practitionerProfile}
                    {...field}
                  />
                )}
              />
            </AppFormField>
          </div>

          <AppFormField
            textLabel="プロジェクト実行者アイコン画像"
            required={true}
            errorText={errors.practitionerThumbnailUrl?.message as string}
          >
            <Controller
              name="practitionerThumbnailUrl"
              control={control}
              render={({ field: { onChange } }) => (
                <AppImageUploading
                  width={'w-[220px]'}
                  height={'h-[220px]'}
                  onChange={(image) => {
                    onChange(image[0]?.file || '');
                    setPractitionerThumbnailPreview(image as ImageListType[]);
                  }}
                  value={practitionerThumbnailPreview}
                  defaultValue={watchedValues?.practitionerThumbnailUrl}
                  disabled={!isRootLanguageMode}
                />
              )}
            />
          </AppFormField>

          <AppFormField textLabel="Xリンク" errorText={errors.xUrl?.message}>
            <TextField
              error={errors.xUrl}
              placeholder="Xリンクを入力する"
              disabled={!isRootLanguageMode}
              {...register('xUrl')}
            />
          </AppFormField>

          <AppFormField textLabel="Instagramリンク" errorText={errors.instagramUrl?.message}>
            <TextField
              error={errors.instagramUrl}
              placeholder="Instagramリンクを入力する"
              disabled={!isRootLanguageMode}
              {...register('instagramUrl')}
            />
          </AppFormField>
        </div>

        <div className="flex gap-3">
          {(status === EStatusProject.DRAFT || status === undefined) && (
            <AppButton type="button" text="下書きを保存" variant="outline" size="lg" onClick={onSaveDraft} />
          )}
          <AppButton type="button" text="次へ" size="lg" onClick={onSubmit} />
        </div>
      </form>
    </div>
  );
};

export default StepOne;
