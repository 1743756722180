import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ELanguage } from '@/common/enums';
import { handleException } from '@/common/exceptions/exception-handling';
import { cleanObject } from '@/common/helpers';
import { uploadAndReplace, uploadPublicAndReplace } from '@/common/helpers/api';
import * as toast from '@/common/helpers/toast';
import Heading1 from '@/components/common/Heading1';
import { useProjectLanguageMode } from '@/contexts';
import { useBeforeUnload, useLoading, useProjectLanguageForm } from '@/hooks';
import { ProjectsListApi } from '@/module/projectsList/api';
import { EProjectCategory, EStatusProject } from '@/module/projectsList/constants';
import {
  IProject,
  IProjectForm,
  IProjectTranslationForm,
  IStepFourForm,
  IStepOneForm,
  IStepThreeForm,
  IStepTwoForm,
} from '@/module/projectsList/project.interface';
import { STEP_FOUR_SCHEMA } from '@/module/projectsList/validations/stepFour.validation';
import { STEP_ONE_SCHEMA } from '@/module/projectsList/validations/stepOne.validations';
import { STEP_TWO_SCHEMA } from '@/module/projectsList/validations/stepTwo.validation';
import { LanguageBar } from '../components/LanguageBar';
import StepBar from '../components/StepBar';
import StepFour from '../components/StepFour/StepFour';
import StepOne from '../components/StepOne/StepOne';
import StepThree, { STEP3_SCREEN } from '../components/StepThree/StepThree';
import StepTwo from '../components/StepTwo/StepTwo';

interface CreateProjectPageProps {}

const CreateProjectPage: React.FC<CreateProjectPageProps> = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState('プロジェクト新規作成ページ');
  const [step, setStep] = useState(1);
  const [allStepsDataJson, setAllStepsDataJson] = useState<string>();
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [isDirtyForm, setIsDirtyForm] = useState<boolean | undefined>();

  const [createError, setCreateError] = useState<string | string[] | undefined>();
  const [currentScreen, setCurrentScreen] = useState<STEP3_SCREEN>(STEP3_SCREEN.list);

  const { openLoading, closeLoading } = useLoading();

  const { rootLanguageMode } = useProjectLanguageMode();
  const {
    getCurrentFormValue,
    getAllFormValues,
    setAllFormValues,
    syncFormValues,
    validateAllForms,
    getAllFormErrors,
  } = useProjectLanguageForm({
    defaultValues: {
      [ELanguage.Japanese]: {} as IProjectForm,
      [ELanguage.English]: {} as IProjectForm,
    },
  });
  const {
    register,
    control,
    watch,
    getValues,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = getCurrentFormValue();

  const hasFormError = useMemo(() => {
    return Object.values(getAllFormErrors()).some((error) => Object.keys(error).length > 0);
  }, [getAllFormErrors]);

  const watchedValues = watch();

  const handleChangeStep = (step: number) => {
    switch (step) {
      case 1:
        setStep(1);
        break;
      case 2:
        setStep(2);
        break;
      case 3:
        setStep(3);
        setCurrentScreen(STEP3_SCREEN.list);
        break;
      case 4:
        setStep(4);
        break;
      default:
        setStep(1);
    }
    generateTitle(step);
  };

  const onBackClick = () => {
    if (step === 1) {
      navigate('/home');
      return;
    }
    if (currentScreen === STEP3_SCREEN.form) {
      setStep(3);
      setCurrentScreen(STEP3_SCREEN.list);
      generateTitle(3);
      return;
    }
    setStep(step - 1);
    generateTitle(step - 1);
  };

  const generateTitle = (step: number) => {
    switch (step) {
      case 1:
        setTitle('プロジェクト新規作成ページ');
        break;
      case 2:
        setTitle('プロジェクト新規作成ページ');
        break;
      case 3:
        setTitle('リターン設定');
        break;
      case 4:
        setTitle('特定商取引に基づく表記');
        break;
      default:
        setTitle('プロジェクト新規作成ページ');
    }
  };

  const uploadFormImage = async (data: Partial<IProject>): Promise<Partial<IProject>> => {
    const updatedData = {
      ...data,
    };

    // Process each property in the object
    for (const key in updatedData) {
      if (updatedData.hasOwnProperty(key)) {
        let value = updatedData[key as keyof IProjectForm];
        if (Array.isArray(value)) {
          // Handle the 'return' array
          for (let i = 0; i < value.length; i++) {
            if (value[i].thumbnailUrl instanceof File) {
              const dataImg = await uploadPublicAndReplace(value[i].thumbnailUrl as File);
              value[i].thumbnailUrl = dataImg;
            }
          }
        } else if (value instanceof File) {
          const dataImg = await uploadAndReplace(value);
          (updatedData as any)[key as keyof Omit<IProjectForm, keyof IStepThreeForm>] = dataImg.fileName;
        }
      }
    }

    return updatedData;
  };

  const handleSaveDraft = async () => {
    try {
      openLoading();
      setCreateError(undefined);
      const draftData: Partial<IProject> = mappingPayload(EStatusProject.DRAFT);
      const dataAfterUploadFile = await uploadFormImage(draftData);
      const payload = cleanObject(dataAfterUploadFile);
      if (!payload.title) {
        const ERROR_TITLE = 'プロジェクトタイトルは必須です。';
        toast.showError(ERROR_TITLE);
        return;
      }
      const response = await ProjectsListApi.saveDraftProject(payload);
      if (response) setIsDirtyForm(false);
    } catch (error: any) {
      const { errorMessages, errorMessage } = handleException(error);
      if (errorMessages || errorMessage) {
        setCreateError(errorMessages ?? errorMessage);
        toast.showError(errorMessage);
      }
    } finally {
      closeLoading();
    }
  };

  const handleSubmitForm = async () => {
    try {
      openLoading();
      setCreateError(undefined);
      const data: Partial<IProject> = mappingPayload(EStatusProject.CREATED);
      const dataAfterUploadFile = await uploadFormImage(data);
      const payload = cleanObject(dataAfterUploadFile);
      const response = await ProjectsListApi.createProject(payload);
      if (response) setIsDirtyForm(false);
    } catch (error: any) {
      const { errorMessages, errorMessage } = handleException(error);
      if (errorMessages || errorMessage) {
        setCreateError(errorMessages ?? errorMessage);
      }
    } finally {
      closeLoading();
    }
  };

  const mappingPayload = (status: EStatusProject): IProject | Partial<IProject> => {
    const allLanguageValues = { ...getAllFormValues() };

    const mappingReturns =
      allLanguageValues[rootLanguageMode].projectReturns?.map((rootReturn) => {
        const { isPublic, ...baseReturnData } = rootReturn;
        const returnTranslations = Object.values(ELanguage)
          .filter((lang) => lang !== rootLanguageMode)
          .map((language) => {
            const translatedReturn = allLanguageValues[language].projectReturns?.find(
              (r) => r.id === baseReturnData.id,
            );
            return {
              language,
              returnName: translatedReturn?.returnName || '',
              content: translatedReturn?.content || '',
            };
          });
        return {
          ...baseReturnData,
          projectReturnTranslations: returnTranslations,
        };
      }) || [];

    const rootData = allLanguageValues[rootLanguageMode];

    const projectTranslations: IProjectTranslationForm[] = Object.values(ELanguage)
      .filter((lang) => lang !== rootLanguageMode)
      .map((language) => {
        const languageData = allLanguageValues[language];
        return {
          language,
          title: languageData.title,
          summary: languageData.summary,
          practitionerName: languageData.practitionerName,
          practitionerProfile: languageData.practitionerProfile,
          content: languageData.content,
          companyName: languageData.companyName,
          businessAddress: languageData.businessAddress,
          businessTel: languageData.businessTel,
          necessaryExpenses: languageData.necessaryExpenses,
          otherInformation: languageData.otherInformation,
        };
      });

    return {
      title: rootData.title,
      thumbnailUrl: rootData.thumbnailUrl,
      targetApAmount: rootData.targetApAmount,
      startDate: rootData.startDate,
      endDate: rootData.endDate,
      summary: rootData.summary,
      category: EProjectCategory.Movie,
      content: rootData.content,
      practitionerName: rootData.practitionerName,
      practitionerProfile: rootData.practitionerProfile,
      practitionerThumbnailUrl: rootData.practitionerThumbnailUrl,
      xUrl: rootData.xUrl,
      instagramUrl: rootData.instagramUrl,
      status,
      today: new Date().toISOString(),
      companyName: rootData.companyName,
      businessAddress: rootData.businessAddress,
      businessTel: rootData.businessTel,
      necessaryExpenses: rootData.necessaryExpenses,
      otherInformation: rootData.otherInformation,
      projectReturns: mappingReturns,
      projectTranslations,
    };
  };

  const validateAndUpdate = (
    object: IStepOneForm | IStepTwoForm | IStepFourForm,
    schema: yup.ObjectSchema<IStepOneForm | IStepTwoForm | IStepFourForm>,
  ) => {
    try {
      schema.validateSync(object, { stripUnknown: true });
      // Validation successful, push step into completedSteps
      return true;
    } catch (error) {
      return false;
    }
  };

  const checkIsDirty = useCallback(() => {
    const isFormChange = Object.keys(dirtyFields).length > 0;
    if (isFormChange) setIsDirtyForm(true);
  }, [dirtyFields]);

  useEffect(() => {
    checkIsDirty();
    const currentData = watchedValues;
    setAllStepsDataJson(JSON.stringify(currentData));
  }, [watchedValues, checkIsDirty, setAllStepsDataJson]);

  useEffect(() => {
    let data = JSON.parse(allStepsDataJson ?? '{}');

    const STEP_ONE = 1;
    const STEP_TWO = 2;
    const STEP_THREE = 3;
    const STEP_FOUR = 4;

    let _completedSteps: number[] = [];
    _completedSteps.push(validateAndUpdate(data, STEP_ONE_SCHEMA) ? STEP_ONE : 0);
    _completedSteps.push(validateAndUpdate(data, STEP_TWO_SCHEMA) ? STEP_TWO : 0);
    _completedSteps.push(data.projectReturns !== undefined && data.projectReturns.length > 0 ? STEP_THREE : 0);
    _completedSteps.push(validateAndUpdate(data, STEP_FOUR_SCHEMA) ? STEP_FOUR : 0);
    setCompletedSteps(_completedSteps.filter((item) => item !== 0));
  }, [allStepsDataJson]);

  useBeforeUnload(isDirtyForm !== undefined ? isDirtyForm : false);

  useEffect(() => {
    if (isDirtyForm === false) {
      navigate('/home');
    }
  }, [isDirtyForm, navigate]);

  return (
    <div className="px-20 py-16">
      <Heading1 text={title} backAction={onBackClick} />

      <div className="py-14">
        <StepBar currentStep={step} completedSteps={completedSteps} onChangeStep={handleChangeStep} />
      </div>

      <div className="pb-10">
        <LanguageBar />
      </div>

      {
        {
          1: (
            <StepOne
              onNextStep={handleChangeStep}
              onSaveDraft={handleSaveDraft}
              errors={errors}
              register={register}
              control={control}
              watch={watch}
            />
          ),
          2: <StepTwo onNextStep={handleChangeStep} onSaveDraft={handleSaveDraft} errors={errors} control={control} />,
          3: (
            <StepThree
              onNextStep={handleChangeStep}
              onSaveDraft={handleSaveDraft}
              currentScreen={currentScreen}
              setCurrentScreen={setCurrentScreen}
              data={getValues('projectReturns')}
              allFormValues={getAllFormValues()}
              setAllFormValues={setAllFormValues}
            />
          ),
          4: (
            <StepFour
              onSubmitForm={handleSubmitForm}
              onSaveDraft={handleSaveDraft}
              createError={createError}
              errors={errors}
              control={control}
              handleSubmit={handleSubmit}
              syncFormValues={syncFormValues}
              validateAllForms={validateAllForms}
              hasFormError={hasFormError}
            />
          ),
        }[step]
      }
    </div>
  );
};

export default CreateProjectPage;
