import { IProjectForm, IStepThreeFormItem } from '@/module/projectsList/project.interface';

export const extractTranslatableProjectFields = (rootData?: IProjectForm, translationData?: Partial<IProjectForm>) => ({
  title: translationData?.title || rootData?.title || '',
  summary: translationData?.summary || rootData?.summary || '',
  content: translationData?.content || rootData?.content || '',
  practitionerName: translationData?.practitionerName || rootData?.practitionerName || '',
  practitionerProfile: translationData?.practitionerProfile || rootData?.practitionerProfile || '',
  companyName: translationData?.companyName || rootData?.companyName || '',
  businessAddress: translationData?.businessAddress || rootData?.businessAddress || '',
  businessTel: translationData?.businessTel || rootData?.businessTel || '',
  necessaryExpenses: translationData?.necessaryExpenses || rootData?.necessaryExpenses || '',
  otherInformation: translationData?.otherInformation || rootData?.otherInformation || '',
});

export const extractTranslatableProjectReturnFields = (
  rootData?: IStepThreeFormItem,
  translationData?: Partial<IStepThreeFormItem>,
) => ({
  returnName: translationData?.returnName || rootData?.returnName || '',
  content: translationData?.content || rootData?.content || '',
});
